<template>
  <nav-header :title="title"></nav-header>
  <div class="container">
    <div class="mb-4" id="titleDiv">
      <h4>{{ $englishPreferred ? "Ranking - Points recent 30 days" : "Rangliste - Point seneste 30 dage" }}</h4>
    </div>
    <div v-if="rankRows.length > 0" id="rankListDiv">
      <div v-for="(rankRow, index) in rankRows" :key="rankRow.ranking">
        <div v-if="index+1 > ((currentPageNumber*pageSize)-pageSize) && index < ((currentPageNumber*pageSize))" class="row">
          <div class="col-1">{{ rankRow.ranking }}.</div>
          <div class="col-8">{{ rankRow.username }}</div>
          <div class="col-3">{{ rankRow.total }}</div>
        </div>
      </div>

      <paginate
        v-if="pageCount > 1"
        class="mt-3"
        :page-count="pageCount"
        :page-range="2"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="$englishPreferred ? 'Prev' : 'Forrige'"
        :next-text="$englishPreferred ? 'Next' : 'Næste'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>
    <div v-if="rankRows.length == 0 && online && receivedEmptyList == false">
      {{ $englishPreferred ? "Fetching ranking list" : "Henter rangliste.." }}
    </div>
    <div v-if="rankRows.length == 0 && !online">
      {{ $englishPreferred ? "Could not fetch ranking - maybe you are offline?" : "Kunne ikke hente rangliste - måske er du offline?" }}
    </div>
    <div v-if="receivedEmptyList == true">Ranglisten er tom</div>
  </div>
</template>

<script>
import NavHeader from "@/components/navbars/NavHeader.vue";
import axios from "axios";
import Paginate from "vuejs-paginate-next";

export default {
  components: {
    NavHeader,
    Paginate,
  },
  data() {
    return {
      title: "Rangliste",
      rankRows: [],
      online: true,
      receivedEmptyList: false,
      pageSize: 10,
      pageCount: 1,
      currentPageNumber: 1,
    };
  },
  methods: {
    getRankings() {
      return axios.get("/spontan/ranking/total/points/month");
    },
    clickCallback(pageNum) {
      this.currentPageNumber = pageNum;
    },
    async prepareData() {
      this.getRankings()
        .then((response) => {
          this.rankRows = response.data;
          if (this.rankRows.length == 0) {
            this.receivedEmptyList = true;
          } else {
            this.pageCount = Math.ceil(this.rankRows.length / this.pageSize);
          }
        })
        .catch((error) => {
          console.log("Server did not respond, updating locally");
          this.online = false;
        });
    },
  },
  mounted() {
    this.prepareData();
  },
};
</script>
